<template>
  <div class="helpcenter" id="helpcenter">
    <content1 />
    <content2 />
    <content3 id="submitticket" />
    <content4 />
  </div>
</template>

<script>
export default {
  name: 'helpcenter',
  components: {
    'content1': () => import('./Content1.vue'),
    'content2': () => import('./Content2.vue'),
    'content3': () => import('./Content3.vue'),
    'content4': () => import('./Content4.vue')
  },
  mounted () {
    var VueScrollTo = require('vue-scrollto');
    var element = location.hash

    if (location.hash) {
      this.$nextTick(() => {
        setTimeout(function() {
          VueScrollTo.scrollTo(element)
        }, 100)
      })
    }
  }
}
</script>

<style scoped lang='scss'></style>
